import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
} from "vue-router";
import {
  useAppStore,
  useCartStore,
  usePatientStore,
  useScheduleFiltersStore,
} from "@/stores";
import { usePosthog } from "@/plugins/posthog";
import { loginToken, loginTokenManager, refresh } from "@/auth";
import { hydrate } from "@/hydrate";
import { WEB_URL } from "@/constants";
import { format, parse } from "date-fns";
import { nextTick } from "vue";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: "/login",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/auth/login-view.vue"),
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      name: "forgot-password",
      path: "/forgot-password?recoveryMethod=:recoveryMethod",
      component: () => import("@/views/auth/forgot-password-view.vue"),
      meta: {
        public: true,
        sendToPosthog: true,
      },
      props: true,
    },
    {
      name: "login.access-contact-selection",
      path: "/access-contact-selection",
      component: () => import("@/views/auth/forgot-password/access-contact-selection-view.vue"),
      meta: {
        public: true,
      },
    },
    {
      name: "login.check-authentication-code",
      path: "/check-authentication-code",
      component: () => import("@/views/auth/forgot-password/check-authentication-code-view.vue"),
      meta: {
        public: true,
      },
    },
    {
      name: "register",
      path: "/register/:userCode?",
      component: () => import("@/views/auth/register-view.vue"),
      meta: {
        public: true,
        sendToPosthog: true,
      },
      props: true,
    },
    {
      path: "/login/two-factor-mother?redirectTo=:redirectTo",
      component: () => import("@/views/auth/two-factor-mother-view.vue"),
      name: "login.two-factor-mother",
      meta: {
        public: true,
        sendToPosthog: false,
      },
      props: true,
    },
    {
      path: "/login/two-factor?redirectTo=:redirectTo?&origin=:origin?",
      component: () => import("@/views/auth/two-factor-view.vue"),
      name: "login.two-factor",
      meta: {
        public: true,
        sendToPosthog: false,
      },
      props: true,
    },
    {
      path: "/login/qrcode/:uuid?",
      component: () => import("@/views/auth/qr-code-view.vue"),
      name: "login.qrcode",
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      path: "/login/face-recognition?redirectTo=:redirectTo&origin=:origin",
      component: () => import("@/views/auth/face-recognition-view.vue"),
      name: "login.face-recognition",
      meta: {
        public: true,
        sendToPosthog: true,
      },
      props: true,
    },
    {
      name: "logout",
      path: "/logout",
      component: () => import("@/views/auth/logout-view.vue"),
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      path: "/user",
      component: () => import("@/views/user/dashboard-view.vue"),
      name: "user.dashboard",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/user/profile",
      name: "user.profile.index",
      component: () => import("@/views/user/profile/profile-view.vue"),
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/user/profile/qrcode",
      name: "user.profile.qrcode",
      component: () => import("@/views/user/profile/qrcode-view.vue"),
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/user/schedules/consultations",
      name: "user.schedules.consultations.index",
      component: () => import("@/views/user/schedules/consultations-view.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/user/schedules/exams",
      name: "user.schedules.exams.index",
      component: () => import("@/views/user/schedules/exams-view.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/user/health",
      redirect: "/user/schedules/history",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/user/schedules/history",
      name: "user.schedules.index",
      component: () => import("@/views/user/schedules/history-view.vue"),
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/user/schedules/:uuid/view",
      name: "user.schedules.view",
      component: () =>
        import("@/views/user/schedules/patient-schedule-view.vue"),
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/schedules/search",
      name: "schedule.search",
      component: () => import("@/views/schedules/schedules-search.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules",
      name: "schedule.index",
      component: () => import("@/views/schedules/schedules-index.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/cart",
      name: "schedule.cart",
      component: () => import("@/views/schedules/schedules-cart-view.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/select-health-plan",
      name: "schedules.select-health-plan",
      component: () =>
        import("@/views/schedules/schedules-select-health-plan.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/upload-medical-guides",
      name: "schedule.upload-medical-guides",
      component: () =>
        import("@/views/schedules/schedule-upload-medical-guide.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/payment",
      name: "schedule.payment",
      component: () => import("@/views/schedules/schedule-payment.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/start-payment",
      name: "schedule.start-payment",
      component: () => import("@/views/schedules/start-payment.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/verify-payment/:cartId",
      sensitive: true,
      props: true,
      name: "schedule.verify-payment",
      component: () => import("@/views/schedules/verify-payment.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/schedules/finished",
      name: "schedule.finished",
      component: () => import("@/views/schedules/schedule-finished.vue"),
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/public/schedules/search",
      name: "public.schedule.search",
      component: () => import("@/views/schedules/public/schedules-search.vue"),
      meta: {
        public: true,
        sendToPosthog: true,
      },
    },
    {
      path: "/public/schedules",
      name: "public.schedule.index",
      component: () => import("@/views/schedules/public/schedules-index.vue"),
      meta: {
        public: true,
        sendToPosthog: true,
      },
    },
    {
      path: "/public/schedules/cart",
      name: "public.schedule.cart",
      component: () =>
        import("@/views/schedules/public/schedules-cart-view.vue"),
      meta: {
        public: true,
        sendToPosthog: true,
      },
    },
    {
      path: "/user/profile/auto-checkin",
      name: "user.profile.auto-checkin",
      component: () => import("@/views/user/profile/auto-checkin-view.vue"),
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/csat/:uuid",
      component: () => import("@/views/survey/csat-view.vue"),
      name: "survey.csat",
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      path: "/nps/:uuid",
      component: () => import("@/views/survey/nps-view.vue"),
      name: "survey.nps",
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      path: "/presence/:uuid",
      component: () => import("@/views/survey/presence-view.vue"),
      name: "presence",
      meta: {
        public: true,
        sendToPosthog: false,
      },
    },
    {
      path: "/user/profile/update",
      component: () => import("@/views/user/profile/update-data-view.vue"),
      name: "user.profile.update-data",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/patient-card",
      component: () => import("@/views/user/profile/patient-card-view.vue"),
      name: "patient-card",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/emergency-card",
      component: () => import("@/views/emergency-card/emergency-card-view.vue"),
      name: "emergency-card",
      meta: {
        sendToPosthog: false,
      },
      beforeEnter: [mustHaveEmergencyProfile],
    },
    {
      path: "/emergency-card/edit",
      component: () =>
        import("@/views/emergency-card/emergency-card-edit-view.vue"),
      name: "emergency-card-edit",
      meta: {
        sendToPosthog: false,
      },
      beforeEnter: [mustHaveEmergencyProfile],
    },
    {
      path: "/emergency-card/consent-term",
      component: () =>
        import("@/views/emergency-card/emergency-card-consent-term-view.vue"),
      name: "emergency-card.consent-term",
      meta: {
        sendToPosthog: false,
      },
      beforeEnter: async () => {
        const appStore = useAppStore();
        const patientStore = usePatientStore();

        appStore.hydrating = true;

        if (patientStore.emergencyProfile === null) {
          appStore.hydrating = false;
          return true;
        }

        return router.push({ name: "emergency-card" });
      },
    },
    {
      path: "/survey/:uuid/:is_preview?",
      meta: {
        sendToPosthog: false,
      },
      redirect: (to) => {
        return window.location.assign(
          `${WEB_URL}/frontend/surveys/${to.params.uuid}`
        );
      },
    },
    {
      path: "/capture-location/:uuid",
      component: () => import("@/views/location/capture-location.vue"),
      name: "location.capture",
      meta: {
        public: true,
        sendToPosthog: true,
      },
    },
    {
      path: "/telemedicine-patient/:uuid",
      component: () =>
        import("@/views/saude-digital/telemedicine/patient-view.vue"),
      name: "telemedicine-patient",
      props: true,
      meta: {
        public: true,
        sendToPosthog: true,
      },
    },
    {
      path: "/user/facial-biometrics",
      component: () => import("@/views/user/facial-biometrics/home-view.vue"),
      name: "user.facial-biometrics",
      meta: {
        sendToPosthog: false,
      },
    },
    {
      path: "/check-in-steps/:uuid/health-questionnaire",
      component: () =>
        import("@/views/check-in-steps/health-questionnaire.vue"),
      name: "check-in-steps.health-questionnaire",
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/check-in-steps/:uuid/health-questionnaire-completed",
      component: () =>
        import("@/views/check-in-steps/health-questionnaire-completed.vue"),
      name: "check-in-steps.health-questionnaire-completed",
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/check-in-steps/:uuid/waiting-for-telemedicine",
      component: () =>
        import("@/views/check-in-steps/waiting-for-telemedicine.vue"),
      name: "check-in-steps.waiting-for-telemedicine",
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/check-in-steps/:uuid",
      component: () => import("@/views/check-in-steps/index.vue"),
      name: "check-in-steps.index",
      meta: {
        sendToPosthog: true,
      },
    },
    {
      path: "/user/schedules/:uuid/telemedicine-guidelines",
      component: () =>
        import("@/views/schedules/telemedicine/guidelines-view.vue"),
      name: "telemedicine-guidelines",
      meta: {
        sendToPosthog: true,
      },
      props: true,
    },
    {
      path: "/user/schedules/:uuid/telemedicine-room",
      component: () => import("@/views/schedules/telemedicine/room-view.vue"),
      name: "telemedicine-room",
      meta: {
        sendToPosthog: true,
      },
      props: true,
    },
  ],
});

function mustHaveEmergencyProfile() {
  const appStore = useAppStore();
  const patientStore = usePatientStore();

  appStore.hydrating = true;

  if (patientStore.emergencyProfile !== null) {
    appStore.hydrating = false;
    return true;
  }

  return router.push({ name: "emergency-card.consent-term" });
}

const directPublicRoutes = [
  "survey.nps",
  "survey.csat",
  "presence",
  "telemedicine-patient",
  "location.capture",
];

const allowedRoutesToBeLoggedInByToken = [
  "user.schedules.view",
  "check-in-steps.index",
  "schedule.search",
];

const flowCartRoutes = [
  "schedule.finished",
  "schedule.payment",
  "schedule.upload-medical-guides",
  "schedule.cart",
  "schedules.select-health-plan",
  "schedule.start-payment",
];

let firstLoad = true;

export const onBeforeEach = async (to) => {
  const appStore = useAppStore();

  const cartStore = useCartStore();
  const patientStore = usePatientStore();
  const scheduleFiltersStore = useScheduleFiltersStore();

  const token = to.query.token;

  if (directPublicRoutes.includes(to.name)) {
    return;
  }

  if (firstLoad) {
    firstLoad = false;
    await refresh();
    await cartStore.hydrate();
  }

  if (
    allowedRoutesToBeLoggedInByToken.includes(to.name) &&
    token &&
    appStore.authenticated === false
  ) {
    try {
      const origin = to.query.origin;

      if (origin === 'manager') {
        await loginTokenManager(token);
      } else {
        await loginToken(token);
      }
    } catch (_) {
      return { name: "login" };
    }
  }

  if (to.name === "login.two-factor" && appStore.twoFactor === null) {
    return { name: "login" };
  }

  if (flowCartRoutes.includes(to.name) && cartStore.isCanceled()) {
    return { name: "schedule.search" };
  }

  if (
    to.meta?.public === true &&
    appStore.authenticated &&
    to.name !== "logout"
  ) {
    return { name: "user.dashboard" };
  }

  if (to.meta?.public !== true) {
    const redirectAfterLogin = appStore.redirectAfterLogin;
    if (redirectAfterLogin !== null) {
      appStore.removeRedirectAfterLogin();
      appStore.forceShowPatientCard = false;

      if (
        flowCartRoutes.includes(redirectAfterLogin) &&
        scheduleFiltersStore.filters?.patient_info?.birth_date &&
        patientStore.patient?.birthday
      ) {
        const localBirthday = format(
          parse(
            scheduleFiltersStore.filters.patient_info.birth_date,
            "dd/MM/yyyy",
            new Date()
          ),
          "yyyy-MM-dd"
        );

        const patientBirthday = format(
          new Date(patientStore.patient.birthday),
          "yyyy-MM-dd"
        );

        if (localBirthday !== patientBirthday) {
          return { name: "schedule.search" };
        }
      }

      return { name: redirectAfterLogin };
    }

    if (
      to.name !== "user.facial-biometrics" &&
      appStore.forceShowPatientCard === true
    ) {
      appStore.forceShowPatientCard = false;
      return { name: "patient-card" };
    }

    if (appStore.hydrated === false) {
      appStore.hydrating = false;
      if (appStore.authenticated === true) {
        await hydrate();
        return to.fullPath;
      } else {
        appStore.setRedirectAfterLogin(to.name);
        return { name: "login" };
      }
    }
  }
};

router.afterEach((to, from, failure) => {
  const posthog = usePosthog();
  const appStore = useAppStore();

  if (!isNavigationFailure(failure)) {
    if (to.meta?.public !== true && appStore.authenticated) {
      const { patient } = usePatientStore();

      nextTick(() => {
        if (patient.uuid && patient.cpf) {
          posthog.identify(patient.uuid, {
            cpf: patient.cpf,
          });
        }
      });
    }

    nextTick(() => {
      posthog.capture("$pageview", {
        path: to.fullPath,
      });
    });
  }
});

router.beforeEach(onBeforeEach);
